"use client";

import { useCssBreakpoint } from "@anthology/shared/src/hooks";
import { Link, Stack, Typography } from "@mui/material";
import { sitePath } from "../const/url";

const Footer = () => {
  const isMobileLayout = useCssBreakpoint("sm");
  const year = new Date().getFullYear();
  return (
    <Stack
      direction="row"
      alignItems={"center"}
      p={4}
      height={70}
      width="100%"
      sx={{ backgroundColor: "#182126" }}
      color="#fff"
      gap={5}
    >
      <Typography>© {year} Absolute</Typography>
      <Link
        href={`${sitePath}privacy-policy`}
      >{`Privacy${isMobileLayout ? "" : " & Cookies"}`}</Link>
      <Link
        href={`${sitePath}terms-conditions`}
      >{`Terms${isMobileLayout ? "" : " & Conditions"}`}</Link>
    </Stack>
  );
};

export default Footer;
