import { emptySlinkyApi as api } from "./slinkyApiBase";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiPreSaveEmailsGetSlinkyEmails: build.query<
      GetApiPreSaveEmailsGetSlinkyEmailsApiResponse,
      GetApiPreSaveEmailsGetSlinkyEmailsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PreSaveEmails/GetSlinkyEmails`,
        params: { slinkyId: queryArg },
      }),
    }),
    getApiSlinkyBuildFromFormat: build.query<
      GetApiSlinkyBuildFromFormatApiResponse,
      GetApiSlinkyBuildFromFormatApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Slinky/BuildFromFormat`,
        params: {
          formatId: queryArg.formatId,
          titleSlinky: queryArg.titleSlinky,
        },
      }),
    }),
    postApiSlinkySlinkyEmailUser: build.mutation<
      PostApiSlinkySlinkyEmailUserApiResponse,
      PostApiSlinkySlinkyEmailUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Slinky/SlinkyEmailUser`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postApiSlinkySlinkyNotifyUsers: build.mutation<
      PostApiSlinkySlinkyNotifyUsersApiResponse,
      PostApiSlinkySlinkyNotifyUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Slinky/SlinkyNotifyUsers`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getApiSlinkySlinkySyncInfo: build.query<
      GetApiSlinkySlinkySyncInfoApiResponse,
      GetApiSlinkySlinkySyncInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Slinky/SlinkySyncInfo`,
        params: {
          formatId: queryArg.formatId,
          titleSlinky: queryArg.titleSlinky,
          syncLinks: queryArg.syncLinks,
          syncArtists: queryArg.syncArtists,
        },
      }),
    }),
    getApiSlinkyGetSlinkySocialDefaultsByArtist: build.query<
      GetApiSlinkyGetSlinkySocialDefaultsByArtistApiResponse,
      GetApiSlinkyGetSlinkySocialDefaultsByArtistApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Slinky/GetSlinkySocialDefaultsByArtist`,
        params: { contributorId: queryArg },
      }),
    }),
    getApiSlinkyPlatformTrackUris: build.query<
      GetApiSlinkyPlatformTrackUrisApiResponse,
      GetApiSlinkyPlatformTrackUrisApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Slinky/PlatformTrackUris`,
        params: { formatId: queryArg.formatId, platform: queryArg.platform },
      }),
    }),
    getApiSlinkyGetSlinkyCurrentSummary: build.query<
      GetApiSlinkyGetSlinkyCurrentSummaryApiResponse,
      GetApiSlinkyGetSlinkyCurrentSummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Slinky/GetSlinkyCurrentSummary`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
          filterJson: queryArg.filterJson,
          maxFuzzyDistance: queryArg.maxFuzzyDistance,
          useRedis: queryArg.useRedis,
        },
      }),
    }),
    getApiSlinkyLandingPageBySlinkyUrlExt: build.query<
      GetApiSlinkyLandingPageBySlinkyUrlExtApiResponse,
      GetApiSlinkyLandingPageBySlinkyUrlExtApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Slinky/LandingPage/${queryArg.slinkyUrlExt}`,
        params: { countryCode: queryArg.countryCode },
      }),
    }),
    getApiSlinkyFindBySlinkyId: build.query<
      GetApiSlinkyFindBySlinkyIdApiResponse,
      GetApiSlinkyFindBySlinkyIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Slinky/Find/${queryArg}` }),
    }),
    postApiSlinkySlinky: build.mutation<
      PostApiSlinkySlinkyApiResponse,
      PostApiSlinkySlinkyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Slinky/Slinky`,
        method: "POST",
        body: queryArg,
      }),
    }),
    putApiSlinkySlinkyBySlinkyId: build.mutation<
      PutApiSlinkySlinkyBySlinkyIdApiResponse,
      PutApiSlinkySlinkyBySlinkyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Slinky/Slinky/${queryArg.slinkyId}`,
        method: "PUT",
        body: queryArg.slinkyVm,
      }),
    }),
    deleteApiSlinkySlinkyBySlinkyId: build.mutation<
      DeleteApiSlinkySlinkyBySlinkyIdApiResponse,
      DeleteApiSlinkySlinkyBySlinkyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Slinky/Slinky/${queryArg}`,
        method: "DELETE",
      }),
    }),
    putApiSlinkyUpdateLive: build.mutation<
      PutApiSlinkyUpdateLiveApiResponse,
      PutApiSlinkyUpdateLiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Slinky/UpdateLive`,
        method: "PUT",
        params: { slinkyId: queryArg.slinkyId, isLive: queryArg.isLive },
      }),
    }),
    getApiSlinkyGetSlinkiesToSync: build.query<
      GetApiSlinkyGetSlinkiesToSyncApiResponse,
      GetApiSlinkyGetSlinkiesToSyncApiArg
    >({
      query: () => ({ url: `/api/Slinky/GetSlinkiesToSync` }),
    }),
    postApiSlinkySlinkyReadyNotifyUsersBySlinkyId: build.mutation<
      PostApiSlinkySlinkyReadyNotifyUsersBySlinkyIdApiResponse,
      PostApiSlinkySlinkyReadyNotifyUsersBySlinkyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Slinky/SlinkyReadyNotifyUsers/${queryArg}`,
        method: "POST",
      }),
    }),
    putApiSlinkySyncChangesBySlinkyId: build.mutation<
      PutApiSlinkySyncChangesBySlinkyIdApiResponse,
      PutApiSlinkySyncChangesBySlinkyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Slinky/SyncChanges/${queryArg.slinkyId}`,
        method: "PUT",
        params: { forceSync: queryArg.forceSync },
      }),
    }),
    getApiSlinkyUrlExtensionExists: build.query<
      GetApiSlinkyUrlExtensionExistsApiResponse,
      GetApiSlinkyUrlExtensionExistsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Slinky/UrlExtensionExists`,
        params: {
          urlExtension: queryArg.urlExtension,
          slinkyId: queryArg.slinkyId,
        },
      }),
    }),
    getApiSlinkyGetStandardCtas: build.query<
      GetApiSlinkyGetStandardCtasApiResponse,
      GetApiSlinkyGetStandardCtasApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Slinky/GetStandardCtas`,
        params: { languageId: queryArg },
      }),
    }),
    getApiSlinkyGetConnectedArtists: build.query<
      GetApiSlinkyGetConnectedArtistsApiResponse,
      GetApiSlinkyGetConnectedArtistsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Slinky/GetConnectedArtists`,
        params: { slinkyId: queryArg },
      }),
    }),
    getApiSlinkyCatalogueSearch: build.query<
      GetApiSlinkyCatalogueSearchApiResponse,
      GetApiSlinkyCatalogueSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SlinkyCatalogue/Search`,
        params: {
          term: queryArg.term,
          limit: queryArg.limit,
          page: queryArg.page,
          offset: queryArg.offset,
          sortBy: queryArg.sortBy,
          filterBy: queryArg.filterBy,
          filterJson: queryArg.filterJson,
          maxFuzzyDistance: queryArg.maxFuzzyDistance,
          useRedis: queryArg.useRedis,
        },
      }),
    }),
    getApiSlinkyCatalogueMinMaxSlinkyClicks: build.query<
      GetApiSlinkyCatalogueMinMaxSlinkyClicksApiResponse,
      GetApiSlinkyCatalogueMinMaxSlinkyClicksApiArg
    >({
      query: () => ({ url: `/api/SlinkyCatalogue/MinMaxSlinkyClicks` }),
    }),
    getApiSlinkyDestinationFindByDestinationId: build.query<
      GetApiSlinkyDestinationFindByDestinationIdApiResponse,
      GetApiSlinkyDestinationFindByDestinationIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/SlinkyDestination/Find/${queryArg}` }),
    }),
    postApiSlinkyDestinationDestination: build.mutation<
      PostApiSlinkyDestinationDestinationApiResponse,
      PostApiSlinkyDestinationDestinationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SlinkyDestination/Destination`,
        method: "POST",
        body: queryArg,
      }),
    }),
    putApiSlinkyDestinationDestinationByDestinationId: build.mutation<
      PutApiSlinkyDestinationDestinationByDestinationIdApiResponse,
      PutApiSlinkyDestinationDestinationByDestinationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SlinkyDestination/Destination/${queryArg.destinationId}`,
        method: "PUT",
        body: queryArg.destinationVm,
      }),
    }),
    deleteApiSlinkyDestinationDestinationByDestinationId: build.mutation<
      DeleteApiSlinkyDestinationDestinationByDestinationIdApiResponse,
      DeleteApiSlinkyDestinationDestinationByDestinationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SlinkyDestination/Destination/${queryArg}`,
        method: "DELETE",
      }),
    }),
    postApiSlinkyEventRecordLandingEvent: build.mutation<
      PostApiSlinkyEventRecordLandingEventApiResponse,
      PostApiSlinkyEventRecordLandingEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SlinkyEvent/RecordLandingEvent`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postApiSlinkyEventRecordClickEvent: build.mutation<
      PostApiSlinkyEventRecordClickEventApiResponse,
      PostApiSlinkyEventRecordClickEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SlinkyEvent/RecordClickEvent`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postApiSlinkyEventRecordPlayEvent: build.mutation<
      PostApiSlinkyEventRecordPlayEventApiResponse,
      PostApiSlinkyEventRecordPlayEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SlinkyEvent/RecordPlayEvent`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getApiSlinkyPreSaveGetUserPlaylists: build.query<
      GetApiSlinkyPreSaveGetUserPlaylistsApiResponse,
      GetApiSlinkyPreSaveGetUserPlaylistsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SlinkyPreSave/GetUserPlaylists`,
        params: { ps: queryArg.ps, p: queryArg.p },
      }),
    }),
    postApiSlinkyPreSaveSavePreSaveByDestination: build.mutation<
      PostApiSlinkyPreSaveSavePreSaveByDestinationApiResponse,
      PostApiSlinkyPreSaveSavePreSaveByDestinationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SlinkyPreSave/SavePreSaveByDestination`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getApiSlinkyPreSaveStartPreSave: build.query<
      GetApiSlinkyPreSaveStartPreSaveApiResponse,
      GetApiSlinkyPreSaveStartPreSaveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SlinkyPreSave/StartPreSave`,
        params: { vm: queryArg.vm, sr: queryArg.sr },
      }),
    }),
    getApiSlinkyPreSavePreSaveCallback: build.query<
      GetApiSlinkyPreSavePreSaveCallbackApiResponse,
      GetApiSlinkyPreSavePreSaveCallbackApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SlinkyPreSave/PreSaveCallback`,
        params: {
          code: queryArg.code,
          state: queryArg.state,
          error: queryArg.error,
        },
      }),
    }),
    postApiSlinkyPreSavePreSave: build.mutation<
      PostApiSlinkyPreSavePreSaveApiResponse,
      PostApiSlinkyPreSavePreSaveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SlinkyPreSave/PreSave`,
        method: "POST",
        body: queryArg,
      }),
    }),
    putApiSlinkyPreSaveUpdatePreSave: build.mutation<
      PutApiSlinkyPreSaveUpdatePreSaveApiResponse,
      PutApiSlinkyPreSaveUpdatePreSaveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SlinkyPreSave/UpdatePreSave`,
        method: "PUT",
        body: queryArg,
      }),
    }),
    getApiSlinkyResourceDestinationCallToActions: build.query<
      GetApiSlinkyResourceDestinationCallToActionsApiResponse,
      GetApiSlinkyResourceDestinationCallToActionsApiArg
    >({
      query: () => ({ url: `/api/SlinkyResource/DestinationCallToActions` }),
    }),
    getApiSlinkyResourceCallToActionGroups: build.query<
      GetApiSlinkyResourceCallToActionGroupsApiResponse,
      GetApiSlinkyResourceCallToActionGroupsApiArg
    >({
      query: () => ({ url: `/api/SlinkyResource/CallToActionGroups` }),
    }),
    getApiSlinkyResourcePixels: build.query<
      GetApiSlinkyResourcePixelsApiResponse,
      GetApiSlinkyResourcePixelsApiArg
    >({
      query: () => ({ url: `/api/SlinkyResource/Pixels` }),
    }),
    getApiSlinkyResourceTrackingTagKeys: build.query<
      GetApiSlinkyResourceTrackingTagKeysApiResponse,
      GetApiSlinkyResourceTrackingTagKeysApiArg
    >({
      query: () => ({ url: `/api/SlinkyResource/TrackingTagKeys` }),
    }),
    getApiSlinkyResourceDefaultTrackingTags: build.query<
      GetApiSlinkyResourceDefaultTrackingTagsApiResponse,
      GetApiSlinkyResourceDefaultTrackingTagsApiArg
    >({
      query: () => ({ url: `/api/SlinkyResource/DefaultTrackingTags` }),
    }),
    getApiSlinkyResourceDestinations: build.query<
      GetApiSlinkyResourceDestinationsApiResponse,
      GetApiSlinkyResourceDestinationsApiArg
    >({
      query: () => ({ url: `/api/SlinkyResource/Destinations` }),
    }),
    getApiSlinkyResourceDestinationTypes: build.query<
      GetApiSlinkyResourceDestinationTypesApiResponse,
      GetApiSlinkyResourceDestinationTypesApiArg
    >({
      query: () => ({ url: `/api/SlinkyResource/DestinationTypes` }),
    }),
    getApiSlinkyResourceSocialMediaPlatforms: build.query<
      GetApiSlinkyResourceSocialMediaPlatformsApiResponse,
      GetApiSlinkyResourceSocialMediaPlatformsApiArg
    >({
      query: () => ({ url: `/api/SlinkyResource/SocialMediaPlatforms` }),
    }),
    getApiSlinkyResourceTerritories: build.query<
      GetApiSlinkyResourceTerritoriesApiResponse,
      GetApiSlinkyResourceTerritoriesApiArg
    >({
      query: () => ({ url: `/api/SlinkyResource/Territories` }),
    }),
    getApiSlinkyResourceLanguages: build.query<
      GetApiSlinkyResourceLanguagesApiResponse,
      GetApiSlinkyResourceLanguagesApiArg
    >({
      query: () => ({ url: `/api/SlinkyResource/Languages` }),
    }),
    getApiSlinkyResourceMediaPlayerPositions: build.query<
      GetApiSlinkyResourceMediaPlayerPositionsApiResponse,
      GetApiSlinkyResourceMediaPlayerPositionsApiArg
    >({
      query: () => ({ url: `/api/SlinkyResource/MediaPlayerPositions` }),
    }),
    getApiSlinkyStatsSlinkyStatsLeaderboard: build.query<
      GetApiSlinkyStatsSlinkyStatsLeaderboardApiResponse,
      GetApiSlinkyStatsSlinkyStatsLeaderboardApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SlinkyStats/SlinkyStatsLeaderboard`,
        params: {
          dimension: queryArg.dimension,
          dimension_tag_name: queryArg.dimensionTagName,
          start_date: queryArg.startDate,
          end_date: queryArg.endDate,
          slinky_id: queryArg.slinkyId,
          client_ids: queryArg.clientIds,
          parent_label_ids: queryArg.parentLabelIds,
          label_ids: queryArg.labelIds,
          artist_ids: queryArg.artistIds,
          format_ids: queryArg.formatIds,
          filter_tag_name: queryArg.filterTagName,
          filter_tag_values: queryArg.filterTagValues,
          limit: queryArg.limit,
          sub_limit: queryArg.subLimit,
          territories: queryArg.territories,
          dataset: queryArg.dataset,
        },
      }),
    }),
    getApiSlinkyStatsSlinkyStatsTimeline: build.query<
      GetApiSlinkyStatsSlinkyStatsTimelineApiResponse,
      GetApiSlinkyStatsSlinkyStatsTimelineApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SlinkyStats/SlinkyStatsTimeline`,
        params: {
          dimension: queryArg.dimension,
          dimension_tag_name: queryArg.dimensionTagName,
          start_date: queryArg.startDate,
          end_date: queryArg.endDate,
          slinky_id: queryArg.slinkyId,
          client_ids: queryArg.clientIds,
          parent_label_ids: queryArg.parentLabelIds,
          label_ids: queryArg.labelIds,
          artist_ids: queryArg.artistIds,
          format_ids: queryArg.formatIds,
          filter_tag_name: queryArg.filterTagName,
          filter_tag_values: queryArg.filterTagValues,
          limit: queryArg.limit,
          sub_limit: queryArg.subLimit,
          territories: queryArg.territories,
          dataset: queryArg.dataset,
        },
      }),
    }),
    getApiSlinkySubscriptionGetSubscriptionsByGuid: build.query<
      GetApiSlinkySubscriptionGetSubscriptionsByGuidApiResponse,
      GetApiSlinkySubscriptionGetSubscriptionsByGuidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SlinkySubscription/GetSubscriptions/${queryArg}`,
      }),
    }),
    putApiSlinkySubscriptionUnsubscribe: build.mutation<
      PutApiSlinkySubscriptionUnsubscribeApiResponse,
      PutApiSlinkySubscriptionUnsubscribeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/SlinkySubscription/Unsubscribe`,
        method: "PUT",
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as slinkyApi };
export type GetApiPreSaveEmailsGetSlinkyEmailsApiResponse = unknown;
export type GetApiPreSaveEmailsGetSlinkyEmailsApiArg = number;
export type GetApiSlinkyBuildFromFormatApiResponse =
  /** status 200 OK */ AcknowledgeActionInt32;
export type GetApiSlinkyBuildFromFormatApiArg = {
  formatId?: number;
  titleSlinky?: boolean;
};
export type PostApiSlinkySlinkyEmailUserApiResponse =
  /** status 200 OK */ boolean;
export type PostApiSlinkySlinkyEmailUserApiArg = NotificationVm;
export type PostApiSlinkySlinkyNotifyUsersApiResponse = unknown;
export type PostApiSlinkySlinkyNotifyUsersApiArg = NotificationVm;
export type GetApiSlinkySlinkySyncInfoApiResponse =
  /** status 200 OK */ SlinkyVm;
export type GetApiSlinkySlinkySyncInfoApiArg = {
  formatId?: number;
  titleSlinky?: boolean;
  syncLinks?: boolean;
  syncArtists?: boolean;
};
export type GetApiSlinkyGetSlinkySocialDefaultsByArtistApiResponse =
  /** status 200 OK */ UspGetSlinkySocialDefaultsResult[];
export type GetApiSlinkyGetSlinkySocialDefaultsByArtistApiArg = number;
export type GetApiSlinkyPlatformTrackUrisApiResponse =
  /** status 200 OK */ string[];
export type GetApiSlinkyPlatformTrackUrisApiArg = {
  formatId?: number;
  platform?: number;
};
export type GetApiSlinkyGetSlinkyCurrentSummaryApiResponse =
  /** status 200 OK */ PageableResultSlinkyCurrentSummary;
export type GetApiSlinkyGetSlinkyCurrentSummaryApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
  filterJson?: string;
  maxFuzzyDistance?: number;
  useRedis?: boolean;
};
export type GetApiSlinkyLandingPageBySlinkyUrlExtApiResponse =
  /** status 200 OK */ SlinkyVm;
export type GetApiSlinkyLandingPageBySlinkyUrlExtApiArg = {
  slinkyUrlExt: string;
  countryCode: string;
};
export type GetApiSlinkyFindBySlinkyIdApiResponse =
  /** status 200 OK */ SlinkyVm;
export type GetApiSlinkyFindBySlinkyIdApiArg = number;
export type PostApiSlinkySlinkyApiResponse = /** status 200 OK */ number;
export type PostApiSlinkySlinkyApiArg = SlinkyVm;
export type PutApiSlinkySlinkyBySlinkyIdApiResponse =
  /** status 200 OK */ number;
export type PutApiSlinkySlinkyBySlinkyIdApiArg = {
  slinkyId: number;
  slinkyVm: SlinkyVm;
};
export type DeleteApiSlinkySlinkyBySlinkyIdApiResponse = unknown;
export type DeleteApiSlinkySlinkyBySlinkyIdApiArg = number;
export type PutApiSlinkyUpdateLiveApiResponse = /** status 200 OK */ number;
export type PutApiSlinkyUpdateLiveApiArg = {
  slinkyId?: number;
  isLive?: boolean;
};
export type GetApiSlinkyGetSlinkiesToSyncApiResponse =
  /** status 200 OK */ number[];
export type GetApiSlinkyGetSlinkiesToSyncApiArg = void;
export type PostApiSlinkySlinkyReadyNotifyUsersBySlinkyIdApiResponse = unknown;
export type PostApiSlinkySlinkyReadyNotifyUsersBySlinkyIdApiArg = number;
export type PutApiSlinkySyncChangesBySlinkyIdApiResponse =
  /** status 200 OK */ number;
export type PutApiSlinkySyncChangesBySlinkyIdApiArg = {
  slinkyId: number;
  forceSync?: boolean;
};
export type GetApiSlinkyUrlExtensionExistsApiResponse = unknown;
export type GetApiSlinkyUrlExtensionExistsApiArg = {
  urlExtension?: string;
  slinkyId?: number;
};
export type GetApiSlinkyGetStandardCtasApiResponse =
  /** status 200 OK */ string[];
export type GetApiSlinkyGetStandardCtasApiArg = number;
export type GetApiSlinkyGetConnectedArtistsApiResponse =
  /** status 200 OK */ UpsGetSlinkyConnectedArtistsResult[];
export type GetApiSlinkyGetConnectedArtistsApiArg = number;
export type GetApiSlinkyCatalogueSearchApiResponse =
  /** status 200 OK */ SlinkyCatalogueVm[];
export type GetApiSlinkyCatalogueSearchApiArg = {
  term?: string;
  limit?: number;
  page?: number;
  offset?: number;
  sortBy?: string;
  filterBy?: string;
  filterJson?: string;
  maxFuzzyDistance?: number;
  useRedis?: boolean;
};
export type GetApiSlinkyCatalogueMinMaxSlinkyClicksApiResponse =
  /** status 200 OK */ {
    [key: string]: number;
  };
export type GetApiSlinkyCatalogueMinMaxSlinkyClicksApiArg = void;
export type GetApiSlinkyDestinationFindByDestinationIdApiResponse =
  /** status 200 OK */ DestinationVm;
export type GetApiSlinkyDestinationFindByDestinationIdApiArg = number;
export type PostApiSlinkyDestinationDestinationApiResponse =
  /** status 200 OK */ number;
export type PostApiSlinkyDestinationDestinationApiArg = DestinationVm;
export type PutApiSlinkyDestinationDestinationByDestinationIdApiResponse =
  /** status 200 OK */ number;
export type PutApiSlinkyDestinationDestinationByDestinationIdApiArg = {
  destinationId: number;
  destinationVm: DestinationVm;
};
export type DeleteApiSlinkyDestinationDestinationByDestinationIdApiResponse =
  unknown;
export type DeleteApiSlinkyDestinationDestinationByDestinationIdApiArg = number;
export type PostApiSlinkyEventRecordLandingEventApiResponse = unknown;
export type PostApiSlinkyEventRecordLandingEventApiArg = LandingEventIdsVm;
export type PostApiSlinkyEventRecordClickEventApiResponse = unknown;
export type PostApiSlinkyEventRecordClickEventApiArg = ClickEventVm;
export type PostApiSlinkyEventRecordPlayEventApiResponse = unknown;
export type PostApiSlinkyEventRecordPlayEventApiArg = PlayEventVm;
export type GetApiSlinkyPreSaveGetUserPlaylistsApiResponse =
  /** status 200 OK */ PlaylistItemVm[];
export type GetApiSlinkyPreSaveGetUserPlaylistsApiArg = {
  ps: string;
  p?: number;
};
export type PostApiSlinkyPreSaveSavePreSaveByDestinationApiResponse = unknown;
export type PostApiSlinkyPreSaveSavePreSaveByDestinationApiArg = SavePreSaveVm;
export type GetApiSlinkyPreSaveStartPreSaveApiResponse = unknown;
export type GetApiSlinkyPreSaveStartPreSaveApiArg = {
  vm?: string;
  sr?: boolean;
};
export type GetApiSlinkyPreSavePreSaveCallbackApiResponse = unknown;
export type GetApiSlinkyPreSavePreSaveCallbackApiArg = {
  code?: string;
  state?: string;
  error?: string;
};
export type PostApiSlinkyPreSavePreSaveApiResponse = unknown;
export type PostApiSlinkyPreSavePreSaveApiArg = PreSaveVm;
export type PutApiSlinkyPreSaveUpdatePreSaveApiResponse = unknown;
export type PutApiSlinkyPreSaveUpdatePreSaveApiArg = UpdatePreSaveVm;
export type GetApiSlinkyResourceDestinationCallToActionsApiResponse =
  /** status 200 OK */ CallToActionVm[];
export type GetApiSlinkyResourceDestinationCallToActionsApiArg = void;
export type GetApiSlinkyResourceCallToActionGroupsApiResponse =
  /** status 200 OK */ CallToActionGroupVm[];
export type GetApiSlinkyResourceCallToActionGroupsApiArg = void;
export type GetApiSlinkyResourcePixelsApiResponse =
  /** status 200 OK */ PixelType[];
export type GetApiSlinkyResourcePixelsApiArg = void;
export type GetApiSlinkyResourceTrackingTagKeysApiResponse =
  /** status 200 OK */ SelectListVm[];
export type GetApiSlinkyResourceTrackingTagKeysApiArg = void;
export type GetApiSlinkyResourceDefaultTrackingTagsApiResponse =
  /** status 200 OK */ SelectListVm[];
export type GetApiSlinkyResourceDefaultTrackingTagsApiArg = void;
export type GetApiSlinkyResourceDestinationsApiResponse =
  /** status 200 OK */ DestinationVm[];
export type GetApiSlinkyResourceDestinationsApiArg = void;
export type GetApiSlinkyResourceDestinationTypesApiResponse =
  /** status 200 OK */ SelectListVm[];
export type GetApiSlinkyResourceDestinationTypesApiArg = void;
export type GetApiSlinkyResourceSocialMediaPlatformsApiResponse =
  /** status 200 OK */ SocialMediaPlatform[];
export type GetApiSlinkyResourceSocialMediaPlatformsApiArg = void;
export type GetApiSlinkyResourceTerritoriesApiResponse =
  /** status 200 OK */ SelectListVm[];
export type GetApiSlinkyResourceTerritoriesApiArg = void;
export type GetApiSlinkyResourceLanguagesApiResponse =
  /** status 200 OK */ SelectListVm[];
export type GetApiSlinkyResourceLanguagesApiArg = void;
export type GetApiSlinkyResourceMediaPlayerPositionsApiResponse =
  /** status 200 OK */ SelectListVm[];
export type GetApiSlinkyResourceMediaPlayerPositionsApiArg = void;
export type GetApiSlinkyStatsSlinkyStatsLeaderboardApiResponse =
  /** status 200 OK */ CompactJsonTableObject;
export type GetApiSlinkyStatsSlinkyStatsLeaderboardApiArg = {
  dimension?: string;
  dimensionTagName?: string;
  startDate?: string;
  endDate?: string;
  slinkyId?: number;
  clientIds?: string;
  parentLabelIds?: string;
  labelIds?: string;
  artistIds?: string;
  formatIds?: string;
  filterTagName?: string;
  filterTagValues?: string;
  limit?: number;
  subLimit?: number;
  territories?: string;
  dataset?: string;
};
export type GetApiSlinkyStatsSlinkyStatsTimelineApiResponse =
  /** status 200 OK */ CompactJsonTableObject;
export type GetApiSlinkyStatsSlinkyStatsTimelineApiArg = {
  dimension?: string;
  dimensionTagName?: string;
  startDate?: string;
  endDate?: string;
  slinkyId?: number;
  clientIds?: string;
  parentLabelIds?: string;
  labelIds?: string;
  artistIds?: string;
  formatIds?: string;
  filterTagName?: string;
  filterTagValues?: string;
  limit?: number;
  subLimit?: number;
  territories?: string;
  dataset?: string;
};
export type GetApiSlinkySubscriptionGetSubscriptionsByGuidApiResponse =
  /** status 200 OK */ SubscriptionVm[];
export type GetApiSlinkySubscriptionGetSubscriptionsByGuidApiArg = string;
export type PutApiSlinkySubscriptionUnsubscribeApiResponse = unknown;
export type PutApiSlinkySubscriptionUnsubscribeApiArg = UnsubscribeVm;
export type AcknowledgeActionInt32 = {
  message?: string | null;
  isError?: boolean;
  value?: number;
};
export type NotificationVm = {
  userEmail?: string | null;
  emailTitle?: string | null;
  emailTemplateId?: string | null;
  tenantName?: string | null;
  image?: string | null;
  mainMessageHtml?: string | null;
  buttonText?: string | null;
  buttonUri?: string | null;
  buttonFullUrl?: string | null;
  afterButtonText?: string | null;
  address?: string | null;
  logo?: string | null;
  facebookUrl?: string | null;
  twitterUrl?: string | null;
  instagramUrl?: string | null;
  linkedInUrl?: string | null;
  formatId: number;
  topicId?: number | null;
  expiryTime?: string | null;
  severity?: string | null;
  icon?: string | null;
  subject?: string | null;
  notificationMsg?: string | null;
  emailCustomArgs?: {
    [key: string]: string | null;
  } | null;
  notifyLabelManagersOnly?: boolean;
};
export type LookAndFeelVm = {
  coverArtImageUrl?: string | null;
  favIconUrl?: string | null;
  backgroundColor?: string | null;
  backgroundBlur?: number | null;
  backgroundImageOpacity?: number | null;
  showConfettiOnLoad?: boolean;
};
export type MediaPlayerVm = {
  playerId?: number | null;
  platformLinkId?: number;
  mediaPlayerUrl?: string | null;
  sequence?: number;
  previewStart?: number;
  isActive?: boolean;
  positionId?: number;
  position?: string | null;
  displayStartDateTime?: string | null;
  displayEndDateTime?: string | null;
  displayDateIsUtc?: boolean;
};
export type CallToActionVm = {
  destinationCallToActionId?: number | null;
  callToActionGroupLinkId?: number | null;
  languageLinkId?: number;
  languageIso3?: string | null;
  callToAction?: string | null;
  isPreReleaseCta?: boolean | null;
  isPostReleaseCta?: boolean | null;
};
export type LinkTerritoryGroupVm = {
  slinkyDestinationLinkTerritorieId?: number | null;
  territoryGroupId?: number | null;
  isVisible?: boolean;
  isUtcdateTime?: boolean | null;
  preOrderDateTime?: string | null;
  releaseDateTime?: string;
  preLinkIdentifierOrUrl?: string | null;
  postLinkIdentifierOrUrl: string;
  preReleaseCtas?: CallToActionVm[] | null;
  postReleaseCtas?: CallToActionVm[] | null;
  territoryIds?: number[] | null;
  destinationFormatId?: string | null;
};
export type LinkCustomSequenceVm = {
  territoryLinkId?: number;
  sequence?: number;
  isHidden?: boolean;
};
export type LinkVm = {
  slinkyDestinationLinkId?: number | null;
  sequence?: number;
  formatId?: number | null;
  isVisible?: boolean;
  isDeleted?: boolean;
  destinationLinkId?: number;
  destinationName?: string | null;
  destinationImageUrl?: string | null;
  requiresFormatDestinationId?: boolean | null;
  allowsPreSave?: boolean;
  platformId?: number | null;
  customDestinationName?: string | null;
  territoryGroups?: LinkTerritoryGroupVm[] | null;
  customTerritorySequences?: LinkCustomSequenceVm[] | null;
};
export type TitleVm = {
  slinkyTitleId?: number | null;
  languageLinkId?: number | null;
  titleText?: string | null;
  subTitleText?: string | null;
  isDefault?: boolean;
  introText?: string | null;
};
export type PixelVm = {
  pixelId?: number | null;
  slinkyToPixelId?: number | null;
  pixelIdentifier?: string | null;
  pixelTypeLinkId?: number;
  slinkyAccountLinkId?: number | null;
  labelLinkId?: number | null;
  contributorLinkId?: number | null;
  anthologyResourceTypeLinkId?: number | null;
  isAutoAdd?: boolean | null;
};
export type SocialVm = {
  socialMediaLinkId?: number | null;
  socialMediaPlatformLinkId?: number;
  socialMediaIdentifier?: string | null;
  sequence?: number;
  platformName?: string | null;
  platformLogoUrl?: string | null;
  pLatformPromptText?: string | null;
  platformPrefix?: string | null;
  anthologyResourceTypeLinkId?: number | null;
};
export type TrackingTagVm = {
  slinkyTrackingTagId?: number | null;
  trackingTagKeyLinkId: number;
  trackingTagValue?: string | null;
};
export type FormatVm = {
  formatId?: number | null;
  title?: string | null;
  subTitle?: string | null;
  displayArtist?: string | null;
  formatType?: string | null;
  isDigital?: boolean;
  customFormatDescription?: string | null;
  formatShortName?: string | null;
};
export type ArtistDestinationIdentifierVm = {
  platformLinkId?: number | null;
  artistDestinationIdentifier?: string | null;
  destinationLinkId?: number | null;
};
export type ArtistVm = {
  artistId?: number | null;
  displayName?: string | null;
  anthologyContributorId?: number | null;
  artistIsni?: string | null;
  artistIdentifiers?: ArtistDestinationIdentifierVm[] | null;
  isFormatArtist?: boolean | null;
};
export type SlinkyVm = {
  appleMusicToken?: string | null;
  userId?: number | null;
  slinkyId?: number | null;
  contributorId?: number | null;
  labelId?: number | null;
  slinkyUrlextension?: string | null;
  isLive?: boolean;
  formatLinkId?: number | null;
  slinkyAccountLinkId?: number | null;
  skipOnSingleVisibleLink?: boolean;
  addAllFormatsFromTitleGroup?: boolean | null;
  requestOriginTerritoryIso2?: string | null;
  requestOriginTerritoryId?: number | null;
  autoAddNewDestinationLinks?: boolean | null;
  syncTitlesWithAnthology?: boolean | null;
  syncArtistsFromAnthology?: boolean | null;
  syncArtworkFromAnthology?: boolean | null;
  signUpPermissionCompanyName?: string | null;
  signUpPermissionTenantName?: string | null;
  showCountdownClock?: boolean;
  syncFromAnthologyInterval?: number | null;
  buildFromFormat?: boolean;
  lastSyncFromAnthology?: string | null;
  lookAndFeel?: LookAndFeelVm;
  mediaPlayers?: MediaPlayerVm[] | null;
  links?: LinkVm[] | null;
  titles?: TitleVm[] | null;
  pixels?: PixelVm[] | null;
  socials?: SocialVm[] | null;
  tags?: TrackingTagVm[] | null;
  relatedFormats?: FormatVm[] | null;
  artists?: ArtistVm[] | null;
};
export type UspGetSlinkySocialDefaultsResult = {
  resourcePrefix?: string | null;
  socialIdentifier?: string | null;
  contributorName?: string | null;
  defaultType?: string | null;
  resourceTypeId?: number | null;
  resourceName?: string | null;
};
export type SlinkyCurrentSummary = {
  id?: number | null;
  artist?: string | null;
  title?: string | null;
  upc?: string | null;
  releaseDate?: string | null;
  statusTypeId?: number;
  statusType?: string | null;
  statusRanking?: number | null;
  releaseLink?: string | null;
  slinkyRequested?: boolean | null;
  slinkyExists?: boolean | null;
  isLive?: boolean | null;
  destinationCount?: number | null;
  amazonLinkCount?: number | null;
  slinkyLink?: string | null;
  slinkyURLExtension?: string | null;
  formatId?: number;
  labelName?: string | null;
  requestedBy?: number | null;
  requestedOn?: string | null;
  requestedByName?: string | null;
  requestedNotCreatedDays?: number | null;
  catNo?: string | null;
  formatType?: string | null;
  isDigital?: boolean | null;
  formatCreatedOn?: string | null;
  originalReleaseDate?: string | null;
  formatTypeGroupId?: number | null;
  formatTypeGroup?: string | null;
  packshotGUID?: string | null;
  isPlatformApproved?: boolean | null;
  isPlatformValidated?: boolean | null;
  deliveryStateLinkId?: number | null;
  titleGroupId?: number | null;
  tenantId?: number | null;
  popularity?: number | null;
  clientId?: number | null;
  formatShortName?: string | null;
  isCatalogueRelease?: boolean | null;
  releaseEmbargoEndDate?: string | null;
  formatSlinkyUserRequestId?: number | null;
  isFormatTitleGroupSlinky?: boolean | null;
  slinkyId?: number | null;
  slinkyDomainLinkId?: number | null;
  skipOnSingleVisibleLink?: boolean | null;
  slinkyCreatedBy?: number | null;
  slinkyCreatedOn?: string | null;
  slinkyCreatedByName?: string | null;
  addAllFormatsFromTitleGroup?: boolean | null;
  firstMadeLiveOn?: string | null;
  madeLiveBy?: number | null;
  slinkyMadeLiveByName?: string | null;
  syncArtistsFromAnthology?: boolean | null;
  syncFromAnthologyInterval?: number | null;
  lastSyncFromAnthology?: string | null;
  syncArtworkFromAnthology?: boolean | null;
  clientNotifiedReadyOn?: string | null;
  labelManagerUserIds?: number[] | null;
};
export type PageableResultSlinkyCurrentSummary = {
  data?: SlinkyCurrentSummary[] | null;
  totalItems?: number;
  page?: number;
  pageCount?: number;
  pageSize?: number;
};
export type UpsGetSlinkyConnectedArtistsResult = {
  slinkyId?: number;
  artistId?: number;
  displayName?: string | null;
  antholologyContributorId?: number | null;
  artistISNI?: string | null;
  artistDestinationIdentifier?: string | null;
  destinationName?: string | null;
};
export type SlinkyCatalogueVm = {
  slinkyId?: number;
  titleText?: string | null;
  subTitleText?: string | null;
  coverArtImageUrl?: string | null;
  links?: number | null;
  minReleaseDate?: string | null;
  slinkyUrlextension?: string | null;
  isLive?: boolean;
  formatLinkId?: number | null;
  contributorId?: number | null;
  labelId?: number | null;
  clicks?: number;
  createdBy?: number;
  createdOn?: string;
  addAllFormatsFromTitleGroup?: boolean;
};
export type DestinationTemplateUrlVm = {
  destinationTemplateUrlid?: number | null;
  destinationTemplateUrl1?: string | null;
  isDefault?: boolean;
};
export type DestinationVm = {
  destinationId?: number | null;
  destinationName?: string | null;
  destinationImageUrl?: string | null;
  destinationImageWidth?: number | null;
  destinationImageHeight?: number | null;
  defaultPreReleaseCallToActionGroupLinkId?: number | null;
  defaultPostReleaseCallToActionGroupLinkId?: number | null;
  sequence?: number;
  platformId?: number | null;
  destinationTypeLinkId?: number;
  clickthroughId?: string | null;
  allowAffiliateLink?: boolean;
  isActive?: boolean | null;
  requiresFormatDestinationId?: boolean | null;
  allowsPreSave?: boolean;
  releaseLinkUrltemplate?: string | null;
  destinationTemplateUrls?: DestinationTemplateUrlVm[] | null;
};
export type LandingEventIdsVm = {
  uid: string;
  slinkyId: number;
  correlationId: string;
  trackingTagsJson?: string | null;
  referrer?: string | null;
};
export type ClickEventVm = {
  landingUid: string;
  correlationId: string;
  slinkyLinkId: number;
  destinationId: number;
  slinkyDestinationTerritoryId: number;
  ctaId?: number | null;
  isClickThrough: boolean;
  redirectUrl?: string | null;
  stateGuid?: string;
};
export type PlayEventVm = {
  landingUid: string;
  correlationId: string;
  slinkyLinkId: number;
  mediaPlayerId?: number | null;
  formatId?: number | null;
  assetId: number;
  formatAssetId?: number | null;
};
export type PlaylistItemVm = {
  id?: string | null;
  text?: string | null;
};
export type SavePreSaveVm = {
  formatLinkId?: number | null;
  preSaveId?: number;
  addRelease?: boolean;
  preSaveAddedToCustomerOn?: string | null;
  releaseRemovedOn?: string | null;
  email?: string | null;
  releaseLinkURL?: string | null;
  releaseArtist?: string | null;
  releaseTitle?: string | null;
  imageURL?: string | null;
  preSaveDestination?: number;
};
export type PreSaveVm = {
  guid?: string | null;
  slinkyLinkId: number;
  formatLinkId?: number | null;
  formatPlatformId?: string | null;
  saveTargetId?: string | null;
  saveTargetName?: string | null;
  referringUrl?: string | null;
  accountType?: string | null;
  requiresEmailOutNow?: boolean;
  followArtistDestinationIds?: string[] | null;
  destinationId?: number;
  token?: string | null;
};
export type UpdatePreSaveVm = {
  id?: string;
  email?: string | null;
  followArtistDestinationIds?: string[] | null;
};
export type CallToActionGroupVm = {
  callToActionGroupId?: number | null;
  groupName?: string | null;
  isStardard?: boolean;
  isPreReleaseCta?: boolean | null;
  isPostReleaseCta?: boolean | null;
  destinationCallToActions?: CallToActionVm[] | null;
};
export type PixelType = {
  pixelTypeId?: number;
  pixelType1?: string | null;
  pixelTypeDisplayName?: string | null;
  pixelSubTypeLinkId?: number;
  isActive?: boolean | null;
  iconName?: string | null;
  validateAgainst?: string | null;
  anthologyResourceTypeLinkId?: number | null;
};
export type SelectListVm = {
  id?: number;
  alternativeId?: number;
  boolVal?: boolean;
  text?: string | null;
  sequence?: number | null;
  type?: string | null;
  description?: string | null;
  permissionLevelRank?: number | null;
  isoCode?: string | null;
  rate?: number | null;
  selected?: boolean | null;
  grouping?: string | null;
  groupingId?: number;
  isDigital?: boolean | null;
  isAudio?: boolean | null;
  isPreferred?: boolean | null;
  imageUrl?: string | null;
  color?: string | null;
};
export type SocialMediaPlatform = {
  socialMediaPlatformId?: number;
  platformName?: string | null;
  platformLogoUrl?: string | null;
  platformPrefix?: string | null;
  exampleValue?: string | null;
  socialMediaHandlePrefix?: string | null;
  promptTextKey?: string | null;
  anthologyResourceTypeLinkId?: number | null;
  defaultSequence?: number;
};
export type CompactJsonTableObject = {
  rowModel?: any | null;
  header?: string[] | null;
  types?: string[] | null;
  data?: any[][] | null;
};
export type SubscriptionVm = {
  customerArtistSubscriptionId?: number;
  artistLinkId?: number;
  artistName?: string | null;
};
export type UnsubscribeVm = {
  guid: string;
  ids: number[];
  forget?: boolean;
};
export const {
  useGetApiPreSaveEmailsGetSlinkyEmailsQuery,
  useGetApiSlinkyBuildFromFormatQuery,
  usePostApiSlinkySlinkyEmailUserMutation,
  usePostApiSlinkySlinkyNotifyUsersMutation,
  useGetApiSlinkySlinkySyncInfoQuery,
  useGetApiSlinkyGetSlinkySocialDefaultsByArtistQuery,
  useGetApiSlinkyPlatformTrackUrisQuery,
  useGetApiSlinkyGetSlinkyCurrentSummaryQuery,
  useGetApiSlinkyLandingPageBySlinkyUrlExtQuery,
  useGetApiSlinkyFindBySlinkyIdQuery,
  usePostApiSlinkySlinkyMutation,
  usePutApiSlinkySlinkyBySlinkyIdMutation,
  useDeleteApiSlinkySlinkyBySlinkyIdMutation,
  usePutApiSlinkyUpdateLiveMutation,
  useGetApiSlinkyGetSlinkiesToSyncQuery,
  usePostApiSlinkySlinkyReadyNotifyUsersBySlinkyIdMutation,
  usePutApiSlinkySyncChangesBySlinkyIdMutation,
  useGetApiSlinkyUrlExtensionExistsQuery,
  useGetApiSlinkyGetStandardCtasQuery,
  useGetApiSlinkyGetConnectedArtistsQuery,
  useGetApiSlinkyCatalogueSearchQuery,
  useGetApiSlinkyCatalogueMinMaxSlinkyClicksQuery,
  useGetApiSlinkyDestinationFindByDestinationIdQuery,
  usePostApiSlinkyDestinationDestinationMutation,
  usePutApiSlinkyDestinationDestinationByDestinationIdMutation,
  useDeleteApiSlinkyDestinationDestinationByDestinationIdMutation,
  usePostApiSlinkyEventRecordLandingEventMutation,
  usePostApiSlinkyEventRecordClickEventMutation,
  usePostApiSlinkyEventRecordPlayEventMutation,
  useGetApiSlinkyPreSaveGetUserPlaylistsQuery,
  usePostApiSlinkyPreSaveSavePreSaveByDestinationMutation,
  useGetApiSlinkyPreSaveStartPreSaveQuery,
  useGetApiSlinkyPreSavePreSaveCallbackQuery,
  usePostApiSlinkyPreSavePreSaveMutation,
  usePutApiSlinkyPreSaveUpdatePreSaveMutation,
  useGetApiSlinkyResourceDestinationCallToActionsQuery,
  useGetApiSlinkyResourceCallToActionGroupsQuery,
  useGetApiSlinkyResourcePixelsQuery,
  useGetApiSlinkyResourceTrackingTagKeysQuery,
  useGetApiSlinkyResourceDefaultTrackingTagsQuery,
  useGetApiSlinkyResourceDestinationsQuery,
  useGetApiSlinkyResourceDestinationTypesQuery,
  useGetApiSlinkyResourceSocialMediaPlatformsQuery,
  useGetApiSlinkyResourceTerritoriesQuery,
  useGetApiSlinkyResourceLanguagesQuery,
  useGetApiSlinkyResourceMediaPlayerPositionsQuery,
  useGetApiSlinkyStatsSlinkyStatsLeaderboardQuery,
  useGetApiSlinkyStatsSlinkyStatsTimelineQuery,
  useGetApiSlinkySubscriptionGetSubscriptionsByGuidQuery,
  usePutApiSlinkySubscriptionUnsubscribeMutation,
} = injectedRtkApi;
